.custom-info-window {
    background-color: rgba(255, 255, 255, 0.75);
    /* 75% opacity */
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    color: #000;
}

.gm-ui-hover-effect {
    display: none !important;
}